<template>

  <b-modal ref="email-sendout-modal" :title="$t('INVOICE.EMAIL_SENDOUT')" v-model="visible">

    <b-card v-if="visible">

      <b-form-group :label="$t('INVOICE.COUNT_SELECTED')" label-for="count">
        <p style="font-weight: bold;">{{ count }}</p>
      </b-form-group>

      <b-form-group :label="$t('INVOICE.PAYMENT_STATUS')" label-for="payment_status">

        <b-form-input autocomplete="off"
          id="payment_status"
          v-model="payment_status"
          :placeholder="$t('INVOICE.PAYMENT_STATUS')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('INVOICE.PRINTED')" label-for="printed">

        <b-form-input autocomplete="off"
          id="printed"
          v-model="printed_status"
          :placeholder="$t('INVOICE.PRINTED')"
          :disabled="true"
        />
      </b-form-group>


      <b-form-group :label="$t('COMMON.CREATED_FROM')" label-for="created_from">
        <b-form-input autocomplete="off"
          id="created_from"
          v-model="local_options.created_from"
          :placeholder="$t('COMMON.CREATED_FROM')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('COMMON.CREATED_TO')" label-for="created_to">
        <b-form-input autocomplete="off"
          id="created_to"
          v-model="local_options.created_to"
          :placeholder="$t('COMMON.CREATED_TO')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('INVOICE.EMAIL_TEMPLATE')" label-for="email_template">
          <b-form-select
            id="email_template"
            v-model="local_options.email_template_id"
            :options="email_templates"
            :state="email_template_id_state"
          />
          <b-form-invalid-feedback>{{$t('INVOICE.INVALID_TEMPLATE')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('INVOICE.INVOICE_TEMPLATE')" label-for="invoice_template">
          <b-form-select
            id="invoice_template"
            v-model="local_options.invoice_template_id"
            :options="invoice_templates"
            :state="invoice_template_id_state"
          />
          <b-form-invalid-feedback>{{$t('INVOICE.INVALID_TEMPLATE')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('INVOICE.REMINDER_DUE_DATE')" label-for="due-date">
        <memlist-date-picker v-model="local_options.due_date"></memlist-date-picker>
      </b-form-group>

      <div class="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('INVOICE.EMAIL_SENDOUT_INFO')}}</div>
      </div>

    </b-card>

    <template #modal-footer>
      <b-button ref="cancel" variant="secondary" @click="cancel()">{{$t('COMMON.CANCEL')}}</b-button>
      <b-button ref="confirm" variant="success" @click="confirm()">{{$t('INVOICE.RUN_JOB')}}</b-button>
    </template>

  </b-modal>

</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';

export default {
  name: 'InvoiceEmailSendoutModal',
  mixins: [ toasts ],
  components: {},
  props: {
    options: {
      default: {}
    },
    count: {
      default: 0
    }
  },
  emits: ['confirm', 'cancel'],
  watch: {
    options: {
      deep: true,
      handler(val) {
        this.local_options = {...val};
      }
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      local_options: {
        email_template_id: { required },
        invoice_template_id: { required }
      }
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'settings']),
    payment_status() {
      switch (this.local_options.payment_status) {
        case 'LATE': return this.$t('INVOICE.LATE');
        case 'PAID': return this.$t('INVOICE.PAID');
        case 'WAIT': return this.$t('INVOICE.WAIT');
        case 'NOT_PAID': return this.$t('INVOICE.NOT_PAID');
        case 'PARTIALLY_PAID': return this.$t('INVOICE.PARTIALLY_PAID');
      }

      return this.$t('COMMON.ALL');
    },
    printed_status() {
      switch (this.local_options.printed) {
        case 1: return this.$t('INVOICE.PRINTED');
        case 0: return this.$t('INVOICE.NOT_PRINTED');
      }

      return this.$t('COMMON.ALL');
    },
    email_template_id_state() {
      return validate_state({ dirty: this.v$.local_options.email_template_id.$dirty, error: this.v$.local_options.email_template_id.$error })
    },
    invoice_template_id_state() {
      return validate_state({ dirty: this.v$.local_options.invoice_template_id.$dirty, error: this.v$.local_options.invoice_template_id.$error })
    }
  },
  methods: {
    async fetch_invoice_templates() {
      try {
        const res = await axios.get(`/invoice_template`);
        if (res.status === 200) {
          this.invoice_templates = res.data.map(item=>({value: item.id, text: item.name}));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_LIST_TEMPLATES'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_LIST_TEMPLATES'));
      }
    },

    async fetch_email_templates() {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          this.email_templates = res.data.filter(item => item.is_email === true).map(item=> ({value: item.template_id, text: item.name}));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_LIST_TEMPLATES'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_LIST_TEMPLATES'));
      }
    },


    async show() {
      this.local_options.due_date = dayjs().add(this.settings.payment_days, 'day').format('YYYY-MM-DD');
      this.visible = true;

      this.$refs['email-sendout-modal'].show();
    },

    hide() {
      this.$refs['email-sendout-modal'].hide();
    },
    cancel() {
      this.visible = false;
      this.$refs['email-sendout-modal'].hide();
      this.$emit("cancel");
    },
    async confirm() {
      const valid = await this.v$.$validate();

      if (valid) {
        this.visible = false;
        this.$refs['email-sendout-modal'].hide();
        this.$emit("confirm", this.local_options);
      }
      else {
        this.v$.local_options.$touch();
      }
    },
    async get_count() {
      delete this.local_options.count;
      try {
        const res = await axios.post(`/invoice/pagination/count`, { options: this.local_options });
        if (res.status === 200) {
          this.local_options.count = res.data.count;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_GET_COUNT'));
      }
    }
  },
  async mounted() {
    this.local_options = {...this.options};

    await this.fetch_email_templates();
    await this.fetch_invoice_templates();
    await this.get_count();
  },
  data() {
    return {
      local_options: {},
      visible: false,
      email_templates: [],
      invoice_templates: []
    };
  },
};
</script>

<style scoped>
</style>

