<template>
  <div>

    <b-row class="my-8">

      <b-col>
        <tip-label class="mb-2" for="field-invoiced_before" :tip="$t('TAB.INVOICE_COMPANIES.TIP_NO_INVOICE_AFTER')">
          {{$t('TAB.INVOICE_COMPANIES.NO_INVOICE_AFTER')}}
        </tip-label>
        <memlist-date-picker
          v-model="invoiced_before"
          id="field-invoiced_before"
        ></memlist-date-picker>
      </b-col>

      <b-col class="align-self-end text-right mx-1">
        <button class="btn btn-primary font-weight-bolder font-size-sm mr-4" @click="show_create_invoices_modal">
          {{$t('TAB.INVOICE_COMPANIES.CREATE_INVOICES', {count: companies_filtered.length})}}
        </button>
      </b-col>

    </b-row>

    <InvoiceCreateModal
      ref="invoice-create-modal"
      :shop_items="shop_items"
      :companies="companies_filtered"
      @confirm="create_invoices"
    />

    <InvoiceCreateModal
      ref="invoice-create-modal-single"
      :shop_items="shop_items"
      :companies="single_selected_company"
      @confirm="create_single_invoice"
    />


    <InvoiceCompaniesTable
      :companies="companies_filtered"
      @create_invoice_for_company="create_invoice_for_company"
    />

    <b-modal ref="confirm-message" :title="$t('TAB.INVOICE_COMPANIES.CONFIRM_TITLE')">
      <div class="d-block text-center">
        <span class="svg-icon svg-icon-md mb-4 text-primary">
          <inline-svg src="/assets/svg/exclamation-circle-fill.svg"></inline-svg>
        </span>
        <p>{{$t('TAB.INVOICE_COMPANIES.CONFIRM_MESSAGE')}}</p>
      </div>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()">{{$t('COMMON.OK')}}</b-button>
      </template>
    </b-modal>

    <b-modal ref="confirm-error" :title="$t('TAB.INVOICE_COMPANIES.CONFIRM_TITLE')">
      <div class="d-block text-center">
        <span class="svg-icon svg-icon-md mb-4 text-danger">
          <inline-svg
            src="/assets/svg/x-circle-fill.svg"
          ></inline-svg>
        </span>
        <p>{{$t('TAB.INVOICE_COMPANIES.CONFIRM_ERROR')}}</p>
      </div>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()">{{$t('COMMON.OK')}}</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import InvoiceCreateModal from '@/view/pages/ml/invoices/InvoiceCreateModal.vue';
import InvoiceCompaniesTable from '@/view/pages/ml/invoices/InvoiceCompaniesTable.vue';


export default {

  name: 'InvoiceCompaniesTab',

  mixins: [ toasts ],

  components: {
    TipLabel, InvoiceCreateModal, InvoiceCompaniesTable
  },

  computed: {
    ...mapGetters(['currentCompanyId','currentPeriodId']),
  },

  watch: {
    'invoiced_before'(value) {
      this.filter_companies();
    },
    'currentPeriodId'(newValue) {
      if (newValue) {
        this.load_companies();
      }
    }
  },

  methods: {

    async create_single_invoice(data) {
      try {

        const res = await axios.post(`/invoice/company/generate`, data);

        if (res.status === 201) {
          this.$refs['confirm-message'].show();
        }
        else {
          this.$refs['confirm-error'].show();
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_COMPANIES.UNABLE_CREATE_INVOICE'));
      }
    },

    create_invoice_for_company(company_id) {
      this.single_selected_company = [this.companies.find(item => item.company_id === company_id)];

      this.$nextTick(()=>{
        this.$refs['invoice-create-modal-single'].show();
      });

    },

    filter_companies() {
      if (!this.invoiced_before) {
        this.companies_filtered = this.companies;
      } else {
        const before = dayjs(this.invoiced_before);
        this.companies_filtered = this.companies.filter(item => (!item.last_invoiced_at || dayjs(item.last_invoiced_at).isBefore(before)));
      }
    },

    show_create_invoices_modal() {
      this.$refs['invoice-create-modal'].show();
    },

    create_invoices(data) {
       axios
        .post(`/invoice/company/generate`, data)
        .then(res => {
          if (res.status === 201) {
            this.$refs['confirm-message'].show();
          }
          else {
            this.$refs['confirm-error'].show();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_COMPANIES.UNABLE_CREATE_INVOICES'));
        });
    },

    load_companies() {
      this.companies = [];
      const loader = this.$loading.show();
      axios
        .post(`/invoice/company/list/${this.currentPeriodId}`, { orderBy: {}, options: this.options })
        .then(res => {
          if (res.status === 200) {
            this.companies = res.data;
            this.filter_companies();
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_COMPANIES.UNABLE_LIST_COMPANIES'));
          }
          loader && loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_COMPANIES.UNABLE_LIST_COMPANIES'));
          loader && loader.hide();
        });
    },

    load_shop_items() {
      const loader = this.$loading.show();
      this.shop_items = [];
      axios
        .get(`/shop_item/admin/${this.currentCompanyId}?period_id=${this.currentPeriodId}`)
        .then(res => {
          if (res.status === 200) {
            this.shop_items = res.data.filter(item => item.receiver_type === 'COMPANY');
            loader && loader.hide();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'TAB.INVOICE_COMPANIES.UNABLE_LIST_SHOP_ITEMS');
          loader && loader.hide();
        });
    }
  },

  mounted() {
    this.load_companies();
    this.load_shop_items();
  },

  data() {
    return {
      invoiced_before: null,
      companies: [],
      companies_filtered: [],
      shop_items: [],

      single_selected_company: []
    };
  }
};

</script>
