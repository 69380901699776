<template>

  <div>

    <MatchInvoiceToBgmax
      ref="match-invoice-modal"
      @on_confirm_save="on_confirm_save_match_bgmax"
    />

    <b-modal size="xl" ref="bgmax-edit" hide-footer :title="$t('INVOICE.MATCH_INVOICE')">

      <b-card>
        <b-row>
          <b-col md="6">
            <b-form-group id="group-created_at" :label="$t('INVOICE.CREATED_AT')">
              <b-form-input
                id="field-created_at"
                v-model="bgmax.created_at"
                :disabled="true"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group id="group-amount" :label="$t('INVOICE.AMOUNT')">
              <b-form-input
                id="field-amount"
                v-model="bgmax.amount"
                :disabled="true"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group id="group-ocr" :label="$t('INVOICE.OCR')">
              <b-form-input
                id="field-ocr"
                v-model="bgmax.ocr"
                :disabled="true"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p>{{ $t('INVOICE.MATCH_OCR_INFO') }}</p>
          </b-col>
        </b-row>

      </b-card>

      <SimpleInvoiceTable
        class="pt-8"
        @on_plus_clicked="on_plus_clicked"
      />

      <b-button class="mt-8" variant="primary" v-on:click="confirm_save">{{$t('COMMON.SAVE')}}</b-button>
      <b-button class="mt-8 ml-3" variant="outline-secondary" @click.prevent="close_clicked" >{{$t('COMMON.CLOSE')}}</b-button>

    </b-modal>


  </div>

</template>
<style lang="scss" scoped>
.form-create-member {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
}
.btn-memberEditForm {
  width: 85px;
}
</style>
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import SimpleInvoiceTable from '@/view/pages/ml/invoices/SimpleInvoiceTable.vue';
import MatchInvoiceToBgmax from '@/view/pages/ml/invoices/MatchInvoiceToBgmax.vue';



export default {
  name: 'bgmax-edit-modal',
  components: {
    MatchInvoiceToBgmax,
    SimpleInvoiceTable
  },
  props: [],
  emits: ['on_match_bgmax_to_invoice', 'on_confirm_save'],
  data() {
    return {
      invoice: {},
      bgmax: {}
    };
  },
  watch: {

  },
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  methods: {

    on_confirm_save_match_bgmax(bgmax_id, invoice_id) {
      this.$emit('on_match_bgmax_to_invoice', bgmax_id, invoice_id);

      this.$refs['bgmax-edit'].hide();
    },

    on_plus_clicked(invoice) {
      /// show the modal to match
      this.invoice = invoice;

      this.$refs['match-invoice-modal'].show(this.bgmax, invoice);

    },

    show(bgmax) {

      this.bgmax = bgmax;

      this.$refs['bgmax-edit'].show();
    },

    confirm_save() {
      this.$emit('on_confirm_save', this.bgmax.id, this.invoice.invoice_id);

      this.$refs['bgmax-edit'].hide();
    },

    close_clicked() {
      this.$refs['bgmax-edit'].hide();
    },

  }
};
</script>
