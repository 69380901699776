<template>
  <div>
    <b-table
      id="companies-table"
      class="mh-100"
      :fields="headers"
      :items="companies"
      head-variant="light"
      sticky-header
      hover
      sort-icon-left
    >
      <template #head(name)="data">
        <tip-label :tip="$t('TAB.INVOICE_COMPANIES.TIP_HEADER_COMPANY')">{{ data.label }}</tip-label>
      </template>

      <template #head(num_members)="data">
        <tip-label :tip="$t('TAB.INVOICE_COMPANIES.TIP_HEADER_MEMBER_COUNT')">{{ data.label }}</tip-label>
      </template>

      <!--template #head(total_amount)="data">
        <tip-label :tip="$t('TAB.INVOICE_COMPANIES.TIP_HEADER_TOTAL_AMOUNT')">{{ data.label }}</tip-label>
      </template-->

      <template #head(last_invoiced_at)="data">
        <tip-label :tip="$t('TAB.INVOICE_COMPANIES.TIP_HEADER_LAST_INVOICE')">{{ data.label }}</tip-label>
      </template>

      <template #cell(actions)="data">
        <div class="text-right">
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="create_invoice_for_company(data.item.company_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>

    </b-table>

</div>
</template>

<script>
import dayjs from 'dayjs';
import TipLabel from '@/view/components/TipLabel.vue';

export default {

  name: "InvoiceCompaniesTable",

  props: {
    companies: []
  },
  emits: ['create_invoice_for_company'],
  components: {
    TipLabel
  },

  computed: {
  },

  methods: {
    create_invoice_for_company(company_id) {
      this.$emit('create_invoice_for_company', company_id);
    }
  },

  data() {
    return {

      headers: [
        {
          key: 'name',
          show: true,
          sortable: true,
          label: this.$t('TAB.INVOICE_COMPANIES.HEADER_COMPANY'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'num_members',
          show: true,
          sortable: false,
          label: this.$t('TAB.INVOICE_COMPANIES.HEADER_MEMBER_COUNT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        /*{
          key: 'total_amount',
          show: false,
          sortable: false,
          label: this.$t('TAB.INVOICE_COMPANIES.HEADER_TOTAL_AMOUNT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },*/
        {
          key: 'last_invoiced_at',
          show: true,
          sortable: false,
          label: this.$t('TAB.INVOICE_COMPANIES.HEADER_LAST_INVOICE'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
          formatter: (_, __, item) => {
            return item.last_invoiced_at ? dayjs(item.last_invoiced_at).format('YYYY-MM-DD') : "";
          },
        },
        {
          key: 'actions',
          show: true,
          sortable: false,
          label: '',
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        }
      ]
    };
  }
};
</script>
