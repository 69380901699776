<template>
  <b-modal size="lg" ref="match-invoice-bgmax" hide-footer :title="$t('INVOICE.MATCH_INVOICE')">

    <b-row>
      <b-col>
        <p>{{ $t('INVOICE.MATCH_PAYMENT_INFO') }}</p>
      </b-col>
    </b-row>

    <b-card :title="$t('INVOICE.PAYMENT')">
      <b-row>
        <b-col>
          <b-form-group id="group-created_at" :label="$t('INVOICE.CREATED_AT')">
            <b-form-input
              id="field-created_at"
              v-model="bgmax.created_at"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-ocr" :label="$t('INVOICE.OCR')">
            <b-form-input
              id="field-ocr"
              v-model="bgmax.ocr"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-amount" :label="$t('INVOICE.AMOUNT')">
            <b-form-input
              id="field-amount"
              v-model="bgmax.amount"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card :title="$t('INVOICE.INVOICE')">

      <b-row>
        <b-col>
          <b-form-group id="group-invoice_id" :label="$t('INVOICE.INVOICE_ID')">
            <b-form-input
              id="field-invoice_id"
              v-model="invoice.invoice_id"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-recipient" :label="$t('INVOICE.RECIPIENT')">
            <b-form-input
              id="field-recipient"
              v-model="recipient"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-created_at" :label="$t('INVOICE.CREATED_AT')">
            <b-form-input
              id="field-created_at"
              v-model="invoice.created_at"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-ocr" :label="$t('INVOICE.OCR')">
            <b-form-input
              id="field-ocr"
              v-model="invoice.ocr"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-amount" :label="$t('INVOICE.AMOUNT')">
            <b-form-input
              id="field-amount"
              v-model="invoice.amount"
              :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

    </b-card>

    <div class="ml-8">
      <b-button class="mt-8" variant="primary" v-on:click="confirm_save">{{$t('COMMON.CONNECT')}}</b-button>
      <b-button class="mt-8 ml-3" variant="outline-secondary" @click.prevent="close_clicked" >{{$t('COMMON.CLOSE')}}</b-button>
    </div>

  </b-modal>
</template>
<style lang="scss" scoped>
.form-create-member {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
}
.btn-memberEditForm {
  width: 85px;
}
</style>
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';


export default {
  name: 'match-invoice-to-bgmax',
  components: {

  },
  emits: ['on_confirm_save'],
  data() {
    return {
      invoice: {},
      bgmax: {}
    };
  },
  watch: {

  },
  computed: {
    ...mapGetters(['currentCompanyId']),
    recipient() {
      if (this.invoice.company) {
        return this.invoice.company.name;
      }

      if (this.invoice.member) {
        return this.invoice.member.firstname + ' ' + this.invoice.member.lastname;
      }

      return '-';
    }
  },
  methods: {

    show(bgmax, invoice) {
      this.bgmax = bgmax;
      this.invoice = invoice;

      this.$refs['match-invoice-bgmax'].show();
    },

    confirm_save() {
      this.$emit('on_confirm_save', this.bgmax.id, this.invoice.invoice_id);

      this.$refs['match-invoice-bgmax'].hide();
    },

    close_clicked() {
      this.$refs['match-invoice-bgmax'].hide();
    },

  }
};
</script>
