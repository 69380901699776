<template>
  <div>

    <b-row class="justify-content-end my-4 mx-1">
      <b-button variant="primary" @click="new_clicked()">(+) {{$t('TAB.INVOICE_TEMPLATES.NEW_TEMPLATE')}}</b-button>
    </b-row>

    <b-table
      id="template-table"
      class="mh-100 table-striped"
      :fields="headers"
      :items="templates"
      head-variant="light"
      sticky-header
      hover
    >
      <template #cell(actions)="data">
        <div class='d-flex justify-content-end'>
          <span>
            <button class="btn btn-icon btn-light btn-sm mr-4 copy-action" @click.prevent="copy_clicked(data.item)">
              <span class="svg-icon svg-icon-md text-primary">
                <inline-svg src="/assets/svg/files.svg"></inline-svg>
              </span>
            </button>
            <button class="btn btn-icon btn-light btn-sm mr-4 edit-action" @click="edit_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/pencil-square.svg"></inline-svg>
              </span>
            </button>
            <button class="btn btn-icon btn-light btn-sm mr-4 delete-action" @click="delete_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/trash2.svg"></inline-svg>
              </span>
            </button>

            <a
              class="btn btn-icon btn-light btn-sm mr-4"
              @click.prevent="preview_clicked(data.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/vial-solid.svg"></inline-svg>
              </span>
            </a>
          </span>
        </div>
      </template>
    </b-table>

</div>
</template>

<script>

export default {
  name: "InvoiceTemplateTable",
  props: {
    templates: []
  },
  emits: ['new_clicked', 'copy_clicked', 'edit_clicked', 'delete_clicked', 'preview_clicked'],
  components: {},
  computed: {},
  methods: {
    new_clicked() {
      this.$emit('new_clicked');
    },

    copy_clicked(template) {
      this.$emit('copy_clicked', template);
    },

    edit_clicked(template) {
      this.$emit('edit_clicked', template);
    },

    delete_clicked(template) {
      this.$emit('delete_clicked', template);
    },

    preview_clicked(template) {
      this.$emit('preview_clicked', template);
    }
  },
  data() {
    return {
      items: [{name: 'Template', created_at: '2023-01-01'}],
      headers: [
        {
          key: 'name',
          show: true,
          sortable: true,
          label: this.$t('TAB.INVOICE_TEMPLATES.TH_NAME'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'created_at',
          show: true,
          sortable: true,
          label: this.$t('TAB.INVOICE_TEMPLATES.TH_CREATED_AT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
        },
        {
          key: 'actions',
          show: true,
          sortable: false,
          label: '',
          thClass: 'w-100',
        }
      ]
    };
  }
};
</script>
