<template>
  <div v-if="local_template">

    <b-form ref="form" @submit.prevent="on_submit" class="">
      <b-row class="mb-4">
        <b-col>
          <required-fields-info/>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-id" label="ID" label-for="input-id">
            <b-form-input
              id="field-id"
              v-model="local_template.id"
              type="text"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-name">
            <tip-label class="mb-2" for="field-name" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_NAME')" asterix>{{$t('TAB.INVOICE_TEMPLATES.NAME')}}</tip-label>
            <b-form-input
              id="field-name"
              v-model="local_template.name"
              type="text"
              :state="name_state"
            />
            <b-form-invalid-feedback>{{$t('TAB.INVOICE_TEMPLATES.INVALID_NAME')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-logo">
            <b-col style="width: 200px">
              <b-row>
                <label for="file" class="btn btn-success w-100">{{$t('TAB.INVOICE_TEMPLATES.UPLOAD_LOGO')}}</label>
                <input id="file" ref="file" type="file" hidden @change="upload_logo"/>
                <b-img v-if="logo_url" :src="logo_url" fluid thumbnail width/>
                <div v-else style="width:200px; height:200px; background: #EEE; display: flex; justify-content: center; align-items: center; border-radius: 5px;">
                    <span class="svg-icon mb-4 text-white">
                      <inline-svg src="/assets/svg/image.svg" height="64" width="64"></inline-svg>
                    </span>
                </div>

              </b-row>
              <b-row v-if="logo_url" class="mt-4">
                <b-button variant="danger" @click.prevent="delete_logo">{{ $t('TAB.INVOICE_TEMPLATES.DELETE_LOGO') }}</b-button>
              </b-row>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <tip-label :tip="$t('TAB.INVOICE_TEMPLATES.TIP_SETTINGS')">
            <h5 class="mb-4 mt-4">{{$t('TAB.INVOICE_TEMPLATES.SETTINGS')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-language">
            <tip-label class="mb-2" for="field-language" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_LANGUAGE')" asterix>{{$t('TAB.INVOICE_TEMPLATES.LANGUAGE')}}</tip-label>
              <b-form-select
                id="field-language"
                v-model="local_template.language"
                :options="lang_options"
                :state="language_state"
              />
              <b-form-invalid-feedback>{{$t('TAB.INVOICE_TEMPLATES.INVALID_LANGUAGE')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="group-type">
            <tip-label class="mb-2" for="field-type" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_TYPE')" asterix>{{$t('TAB.INVOICE_TEMPLATES.TYPE')}}</tip-label>
            <b-form-select
              id="field-type"
              v-model="local_template.type"
              :options="type_options"
              :state="type_state"
            />
            <b-form-invalid-feedback>{{$t('TAB.INVOICE_TEMPLATES.INVALID_TYPE')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-use_bpost_image">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="field-use_bpost_image" type="checkbox" name="" v-model="local_template.use_bpost_image"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="field-use_bpost_image" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_USE_BPOST_IMAGE')">{{$t('TAB.INVOICE_TEMPLATES.USE_BPOST_IMAGE')}}</tip-label>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-header">
            <tip-label class="mb-2" for="field-header" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_HEADER')" asterix>{{$t('TAB.INVOICE_TEMPLATES.HEADER')}}</tip-label>
              <b-form-select
                id="field-header"
                v-model="local_template.header"
                :options="header_options"
                :state="header_state"
              />
              <b-form-invalid-feedback>{{$t('TAB.INVOICE_TEMPLATES.INVALID_HEADER')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-reminder">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="field-reminder" type="checkbox" name="" v-model="local_template.reminder"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="field-reminder" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_REMINDER')">{{$t('TAB.INVOICE_TEMPLATES.REMINDER')}}</tip-label>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group v-if="local_template.reminder" id="group-reminder_fee">
            <tip-label class="mb-2" for="field-reminder_fee" asterix>{{$t('TAB.INVOICE_TEMPLATES.REMINDER_FEE')}}</tip-label>
              <b-form-input
                id="field-reminder_fee"
                v-model="local_template.reminder_fee"
                type="text"
                v-numeric
                trim
                :state="reminder_fee_state"
              />
              <b-form-invalid-feedback>{{$t('TAB.INVOICE_TEMPLATES.INVALID_REMINDER_FEE')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <tip-label :tip="$t('TAB.INVOICE_TEMPLATES.TIP_SENDER')">
            <h5 class="mb-4 mt-4">{{$t('TAB.INVOICE_TEMPLATES.SENDER')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-reminder" type="checkbox" name="" v-model="local_template.contact_url"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('TAB.INVOICE_TEMPLATES.SENDER_WEBADDRESS') }}</span>
          </div>
        </b-col>
        <b-col md="4">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-reminder" type="checkbox" name="" v-model="local_template.contact_phone"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('TAB.INVOICE_TEMPLATES.SENDER_PHONE') }}</span>
          </div>
        </b-col>
        <b-col md="4">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-reminder" type="checkbox" name="" v-model="local_template.contact_orgnr"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('TAB.INVOICE_TEMPLATES.SENDER_ORGNUMBER') }}</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-reminder" type="checkbox" name="" v-model="local_template.contact_name"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('TAB.INVOICE_TEMPLATES.SENDER_CONTACT') }}</span>
          </div>
        </b-col>
        <b-col md="4">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-reminder" type="checkbox" name="" v-model="local_template.contact_email"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('TAB.INVOICE_TEMPLATES.SENDER_EMAIL') }}</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <tip-label :tip="$t('TAB.INVOICE_TEMPLATES.TIP_BOX')">
            <h5 class="mb-4 mt-8">{{$t('TAB.INVOICE_TEMPLATES.BOX')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-box_text">
            <tip-label class="mb-2" for="field-box_text" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_BOX_TEXT')" asterix>{{$t('TAB.INVOICE_TEMPLATES.BOX_TEXT')}}</tip-label>
              <b-form-textarea
                id="field-box_text"
                v-model="local_template.box_text"
                size="sm"
                :state="box_text_state"
              />
              <b-form-invalid-feedback>{{$t('TAB.INVOICE_TEMPLATES.INVALID_BOX_TEXT')}}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group id="group-box_bg">
            <tip-label class="mb-2" for="field-box_bg" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_BOX_BG')" asterix>{{$t('TAB.INVOICE_TEMPLATES.BOX_BG')}}</tip-label>
            <color-picker-input id="field-box_bg" v-model="local_template.box_bg"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="group-box_fg">
            <tip-label class="mb-2" for="field-box_fg" :tip="$t('TAB.INVOICE_TEMPLATES.TIP_BOX_FG')" asterix>{{$t('TAB.INVOICE_TEMPLATES.BOX_FG')}}</tip-label>
            <color-picker-input id="field-box_fg" v-model="local_template.box_fg"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button ref="save" class="mt-8 save" variant="primary" type="submit">{{$t('COMMON.SAVE')}}</b-button>
          <b-button class="mt-8 mx-4 preview" variant="secondary" @click="$emit('preview:template', local_template)" >{{$t('COMMON.PREVIEW')}}</b-button>
          <b-button class="mt-8 cancel" variant="danger" @click="$emit('cancel')" >{{$t('COMMON.CANCEL')}}</b-button>
        </b-col>
      </b-row>

    </b-form>

  </div>

</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';

export default {
  name: 'InvoiceTemplateEditor',

  components: { TipLabel, RequiredFieldsInfo, ColorPickerInput },

  mixins: [ toasts ],

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      local_template: {
        name: { required },
        language: { required },
        type: { required },
        header: { required },
        reminder_fee: { required: requiredIf(() => this.local_template.reminder) },
        box_text: { required },
      }
    };
  },

  computed: {
    ...mapGetters(['currentCompanyId']),
    logo_url() {
      return this.local_template && this.local_template.logo_file ? this.local_template.logo_file.public_path : null;
    },
    name_state() {
      return validate_state({ dirty: this.v$.local_template.name.$dirty, error: this.v$.local_template.name.$error });
    },
    language_state() {
      return validate_state({ dirty: this.v$.local_template.language.$dirty, error: this.v$.local_template.language.$error });
    },
    type_state() {
      return validate_state({ dirty: this.v$.local_template.type.$dirty, error: this.v$.local_template.type.$error });
    },
    header_state() {
      return validate_state({ dirty: this.v$.local_template.header.$dirty, error: this.v$.local_template.header.$error });
    },
    reminder_fee_state() {
      return validate_state({ dirty: this.v$.local_template.reminder_fee.$dirty, error: this.v$.local_template.reminder_fee.$error });
    },
    box_text_state() {
      return validate_state({ dirty: this.v$.local_template.box_text.$dirty, error: this.v$.local_template.box_text.$error });
    },
  },

  directives: {
    numeric: {
      inserted: (el) => {
        el.oninput = (ev) => {
          const value = parseInt(ev.target.value, 10);
          el.value = isNaN(value) ? '' : value;
        };
      }
    }
  },

  props: {
    template: { default: null }
  },
  emits: ['create:template', 'update:template', 'preview:template', 'cancel'],

  watch: {
    template: {
      handler(val) {
        this.local_template = { ...val };
      },
      deep: true
    },
    'template.id'() {
      if (this.$refs.observer) {
        // need to reset validation when changing template so all fields are set to neutral state
        this.reset_validation();
      }
    }
  },

  methods: {
    async delete_logo() {

      this.local_template.logo_file = null;
      this.local_template.logo_file_id = null;

      if (this.local_template.id) {
        const valid = await this.v$.$validate();

        if (!valid) {
          this.v$.$touch();
          return;
        }

        this.$emit('update:template', this.local_template, false);
      }
    },

    reset_validation() {
      if (this.local_template) {
        this.$nextTick(() => this.v$.$reset());
      }
    },

    upload_logo() {

      const file = this.$refs.file.files[0];
      const data = new FormData();
      data.append('file', file);
      data.append('file_source', 'template_logo');
      data.append('company_id', this.currentCompanyId);
      data.append('is_public', true);

      axios
        .post(`/fileupload`, data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TAB.INVOICE_TEMPLATES.UPLOADED'));
          this.local_template.logo_file = res.data.fileobjs[0];
          this.local_template.logo_file_id = this.local_template.logo_file.file_id;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_UPLOAD'));
        });
    },

    async validate() {
      return await this.v$.$validate();
    },

    async on_submit() {
      const valid = await this.v$.$validate();
      console.log('valid', valid)
      if (valid) {
        this.$emit(this.local_template.id ? 'update:template' : 'create:template', this.local_template, false);
      }
    },
  },

  mounted() {
    if (this.template) {
      this.local_template = { ...this.template };
    }
  },

  data() {
    return {
      local_template: {},
      type_options: [
        { value: 'MEMBER', text: this.$t('TAB.INVOICE_TEMPLATES.TYPES.MEMBER') },
        { value: 'COMPANY', text: this.$t('TAB.INVOICE_TEMPLATES.TYPES.COMPANY') },
        { value: 'MEMBER_RENEW', text: this.$t('TAB.INVOICE_TEMPLATES.TYPES.MEMBER_RENEW') },
      ],
      lang_options: [
        { value: 'sv', text: this.$t('COMMON.SV') },
        { value: 'en', text: this.$t('COMMON.EN') },
      ],
      header_options: [
        this.$t('TAB.INVOICE_TEMPLATES.HEADER_OPTION1'),
        this.$t('TAB.INVOICE_TEMPLATES.HEADER_OPTION2'),
        this.$t('TAB.INVOICE_TEMPLATES.HEADER_OPTION3'),
      ],
    };
  }

};

</script>
