<template>
  <b-modal
    ref="invoice-template-editor-modal"
    body-class="pt-0"
    size="lg"
    hide-footer
    :title="modal_title"
  >
    <InvoiceTemplateEditor
      :template="template"
      @update:template="update_template"
      @create:template="create_template"
      @preview:template="preview_template"
      @cancel="hide"
    />
  </b-modal>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import InvoiceTemplateEditor from '@/view/pages/ml/invoices/InvoiceTemplateEditor.vue';

export default {
  name: 'InvoiceTemplateEditorModal',
  mixins: [ toasts ],
  props: ['template'],
  emits: ['create', 'update', 'preview'],
  components: {
    InvoiceTemplateEditor
  },
  computed: {
    modal_title() {
      return this.template && this.template.id
        ? this.$t('TAB.INVOICE_TEMPLATES.UPDATE_TITLE')
        : this.$t('TAB.INVOICE_TEMPLATES.CREATE_TITLE')
    },
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    create_template(item) {
      this.$emit('create', item);
    },
    update_template(item) {
      this.$emit('update', item);
    },
    preview_template(item) {
      this.$emit('preview', item);
    },
    show() {
      this.$refs['invoice-template-editor-modal'].show();
    },
    hide() {
      this.$refs['invoice-template-editor-modal'].hide();
    }
  }
};
</script>
