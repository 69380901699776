<template>
  <div>

    <b-row>
      <b-col>
        <b-form-group
          id="input-group-clearing_no"
          :label="$t('COMPANY.CLEARING_NO')"
          label-for="input-clearing_no"
        >
          <b-form-input
            id="input-clearing_no"
            v-model="form.clearing_no"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-account_no"
          :label="$t('COMPANY.ACCOUNT_NO')"
          label-for="input-account_no"
        >
          <b-form-input
            id="input-account_no"
            v-model="form.account_no"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>


    <RightSaveButton
      ref="save-button"
      :text="$t('COMMON.SAVE')"
      @clicked="submit_clicked()"
    />


  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {

  name: 'InvoiceSettingsTab',

  mixins: [ toasts ],

  components: {
    TipLabel,
    RightSaveButton
  },

  computed: {
    ...mapGetters(['currentCompanyId','currentPeriodId','sid']),
  },

  watch: {

  },

  methods: {
    async submit_clicked() {
      try {
        await this.save_company();
      }
      catch (err) {
        console.error('submit_clicked error', err)
      }

      this.$refs['save-button'].stop();
      
    },
    async load_company() {
      try {
        const result = await axios.get(`/company/${this.sid}`);

        if (result.status === 200) {
          this.form = {
            account_no:  result.data.account_no,
            clearing_no: result.data.clearing_no
          }
        }
      }
      catch (err) {
        console.error('load_company', err);
      }
    },

    async save_company() {
      try {
        const result = await axios.put(`/company/${this.sid}`, this.form);

        if (result.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));

          this.$emit('updated', this.form)
        }
      }
      catch (err) {
        console.error('load_company', err);
      }
    }


  },

  mounted() {
    this.load_company();
  },

  data() {
    return {
      form: {
        clearing_no: '',
        account_no: ''
      }
    };
  }
};

</script>
