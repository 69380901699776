<template>
  <div>
    <InvoiceTemplateTable
      :templates="templates"
      @new_clicked="new_template_clicked"
      @edit_clicked="edit_template_clicked"
      @copy_clicked="copy_template_clicked"
      @delete_clicked="delete_template_clicked"
      @preview_clicked="preview_template_clicked"
    />

    <InvoiceTemplateEditorModal
      ref="invoice-template-editor-modal"
      :template="template"
      @create="create_template"
      @update="update_template"
      @preview="preview_template_clicked"
      @cancel="cancel_clicked"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import InvoiceTemplateTable from '@/view/pages/ml/invoices/InvoiceTemplateTable.vue';
import InvoiceTemplateEditor from '@/view/pages/ml/invoices/InvoiceTemplateEditor.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import InvoiceTemplateEditorModal from '@/view/pages/ml/invoices/InvoiceTemplateEditorModal.vue';

export default {
  name: 'InvoiceTemplateTab',
  mixins: [ toasts ],
  components: {
    InvoiceTemplateEditorModal,
    InvoiceTemplateTable, InvoiceTemplateEditor
  },
  computed: {
    ...mapGetters(['currentCompanyId']),
  },
  methods: {
    async preview_template_clicked(template) {
      try {
        if (!template.id) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_PREVIEW'));
          return;
        }
        const response = await axios.post(`/invoice_template/preview/${template.id}`);

        if (response.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${response.data.linkstr}`, response.data.name);
        }
      }
      catch (err) {
        console.error('preview_template_clicked', err);
      }
    },

    show_invoice_template_editor_modal() {
      this.$refs['invoice-template-editor-modal'].show();
    },

    hide_invoice_template_editor_modal() {
      this.$refs['invoice-template-editor-modal'].hide();
    },

    new_template_clicked() {
      this.template = { box_bg: '#eeeeee', box_fg: '#000000' };
      this.show_invoice_template_editor_modal();
    },

    copy_template_clicked(template) {
      this.template = { ...template, id: null };
      this.show_invoice_template_editor_modal();
    },

    edit_template_clicked(template) {
      this.template = template;
      this.show_invoice_template_editor_modal();
    },

    async delete_template_clicked(template) {
      try {
        const res = await axios.delete(`/invoice_template/${template.id}`);
        if (res.status === 204) {
          this.templates = this.templates.filter(item => item.id !== template.id);
          if (this.template && this.template.id === template.id) { this.template = null; }
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TAB.INVOICE_TEMPLATES.DELETED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_DELETE'));
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_DELETE'));
      }
    },

    async create_template(template, close_editor) {
      try {
        const res = await axios.post(`/invoice_template`, template);
        if (res.status === 201) {
          this.templates.push(template);
          if (close_editor) {
            template = null;
          }
          this.toastr('success', this.$t('COMMON.OK'), this.$t('TAB.INVOICE_TEMPLATES.CREATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_CREATE'));
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_CREATE'));
      }
    },

    async update_template(template, close_editor) {
      try {
        const res = await axios.put(`/invoice_template/${template.id}`, template);
        if (res.status === 204) {
          const index = this.templates.findIndex(item => item.id === template.id);
          this.templates[index] = template;
          // Vue3 compatability for Vue.set
          this.templates = [...this.templates];

          if (close_editor) {
            template = null;
          }

          this.toastr('success', this.$t('COMMON.OK'), this.$t('TAB.INVOICE_TEMPLATES.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_UPDATE'));
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_UPDATE'));
      }
    },

    async get_templates() {
      const loader = this.$loading.show();

      try {
        const res = await axios.get(`/invoice_template`);
        this.templates = res.data;
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TAB.INVOICE_TEMPLATES.UNABLE_LIST'));
      } finally {
        loader && loader.hide();
      }
    },

    cancel_clicked() {
      this.template = null;
    },
  },

  mounted() {
    this.get_templates()
  },

  data() {
    return {
      templates: [],
      template: null
    };
  }
};

</script>
