<template>

  <b-modal :title="$t('MODAL.INVOICE_CREATE.TITLE')" v-model="visible" size="xl">

    <div v-if="visible">

      <b-row>
        <b-col>
          <b-form-group id="group-invoice_count">
            <tip-label class="mb-2" for="field-invoice_count" :tip="$t('MODAL.INVOICE_CREATE.TIP_INVOICE_COUNT')">
                {{$t('MODAL.INVOICE_CREATE.INVOICE_COUNT')}}
            </tip-label>
            <b-form-input
              id="field-invoice_count"
              type="text"
              v-model="companies.length"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="group-companies">
            <tip-label
              class="mb-2"
              for="field-companies"
              :tip="$t('MODAL.INVOICE_CREATE.TIP_PREVIEW_AS')"
              >
                {{$t('MODAL.INVOICE_CREATE.PREVIEW_AS')}}
            </tip-label>
            <b-form-select
              id="field-companies"
              :options="companies_select"
              v-model="selected_company"
              @change="selected_company_changed()"
            />
          </b-form-group>
        </b-col>
        <b-col>
        </b-col>
      </b-row>

      <b-row class="mb-8">
        <b-col>
          <b-table
            id="articles-table"
            :fields="headers"
            :items="invoice_shop_items"
            head-variant="light"
            sticky-header
            hover
          >
            <template #head(shop_item)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_ARTICLE')">{{ data.label }}</tip-label>
            </template>

            <template #head(amount)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_AMOUNT')">{{ data.label }}</tip-label>
            </template>

            <template #head(currency)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_CURRENCY')">{{ data.label }}</tip-label>
            </template>

            <template #head(quantity_type)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_QUANTITY_TYPE')">{{ data.label }}</tip-label>
            </template>

            <template #head(quantity)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_QUANTITY')">{{ data.label }}</tip-label>
            </template>

            <template #head(vat)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_VAT')">{{ data.label }}</tip-label>
            </template>

            <template #head(tot_vat)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_TOT_VAT')">{{ data.label }}</tip-label>
            </template>

            <template #head(tot_amount)="data">
              <tip-label :tip="$t('MODAL.INVOICE_CREATE.TIP_TH_TOT_AMOUNT')">{{ data.label }}</tip-label>
            </template>

            <template #cell(shop_item)="data">
              <div style='min-width: 260px'>
                <b-form-select size="sm" v-model="data.item.shop_item" :options="shop_items_select" @change="shop_item_changed(data)"/>
              </div>
            </template>

            <template #cell(amount)="data">
              <div style='min-width: 80px;'>
                <b-form-input size="sm" v-model="data.item.amount" v-numeric @input="amount_changed(data)"/>
              </div>
            </template>

            <template #cell(quantity)="data" >
              <div style='min-width: 80px;'>
                <b-form-input
                  size="sm"
                  v-model="data.item.quantity"
                  v-numeric
                  :disabled="data.item.quantity_type !== 'QUANTITY'"
                  @input="quantity_changed(data)"
                />
              </div>
            </template>

            <template #cell(actions)="data">
            <div class='d-flex justify-content-end'>
              <span>
                <button class="btn btn-icon btn-light btn-sm mr-4" @click="delete_shop_item(data)">
                  <span class="svg-icon text-primary">
                    <inline-svg src="/assets/svg/trash2.svg"></inline-svg>
                  </span>
                </button>
              </span>
            </div>
          </template>

          </b-table>
          <b-button variant="success" :disabled="!shop_items.length" @click="add_shop_item()">
            {{$t('MODAL.INVOICE_CREATE.ADD_ITEM')}}
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-tot_vat">
            <tip-label class="mb-2" for="field-tot_vat" :tip="$t('MODAL.INVOICE_CREATE.TIP_TOT_VAT')">
              {{$t('MODAL.INVOICE_CREATE.TOT_VAT')}}
            </tip-label>
            <b-form-input  id="field-tot_vat" type="text" v-model="totals.vat" disabled>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="group-exc_vat">
            <tip-label class="mb-2" for="field-exc_vat" :tip="$t('MODAL.INVOICE_CREATE.TIP_EXC_VAT')">
              {{$t('MODAL.INVOICE_CREATE.EXC_VAT')}}
            </tip-label>
            <b-form-input  id="field-exc_vat" type="text" v-model="totals.exc_vat" disabled>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="group-inc_vat">
            <tip-label class="mb-2" for="field-inc_vat" :tip="$t('MODAL.INVOICE_CREATE.TIP_INC_VAT')">
              {{$t('MODAL.INVOICE_CREATE.INC_VAT')}}
            </tip-label>
            <b-form-input  id="field-inc_vat" type="text" v-model="totals.inc_vat" disabled>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-due_date">
            <tip-label class="mb-2" for="field-due_date" :tip="$t('MODAL.INVOICE_CREATE.TIP_DUE_DATE')">
              {{$t('MODAL.INVOICE_CREATE.DUE_DATE')}}
            </tip-label>
            <memlist-date-picker
              v-model="due_date"
              id="field-due_date"
            ></memlist-date-picker>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group id="group-box_text">
            <tip-label class="mb-2" for="field-box_text" :tip="$t('MODAL.INVOICE_CREATE.TIP_BOX_TEXT')">
              {{$t('MODAL.INVOICE_CREATE.BOX_TEXT')}}
            </tip-label>
            <b-form-textarea id="field-box_text" size="sm" v-model="box_text" />
          </b-form-group>
        </b-col>
      </b-row>

    </div>

    <template #modal-footer>
      <b-button ref="cancel" variant="secondary" @click="cancel()">{{$t('COMMON.CANCEL')}}</b-button>
      <b-button ref="confirm" variant="success" @click="confirm()">{{$t('MODAL.INVOICE_CREATE.CREATE', { count: companies.length })}}</b-button>
    </template>

  </b-modal>



</template>

<style>
.modal-xl {
  max-width: 80% !important;
}

</style>


<script>
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';

export default {
  name: 'InvoiceCreateModal',
  mixins: [],
  components: {
    TipLabel,
  },

  props: {
    shop_items: [],
    companies: [],
  },
  emits: ['cancel', 'confirm'],
  watch: {
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

    companies_select() {
      return this.companies.map((item, index)=>({ value: index, text: item.name}));
    },
    shop_items_select() {
      return this.shop_items.map((item, index)=>({ value: index, text: item.name }));
    }
  },

  directives: {
    numeric: {
      inserted: (el) => {
        el.oninput = (ev) => {
          const value = parseInt(ev.target.value, 10);
          el.value = isNaN(value) ? '' : value;
        };
      }
    }
  },

  methods: {
    show() {
      this.selected_company = 0;
      this.company_member_count = this.companies.length ? this.companies[0].num_members : 0;
      this.invoice_shop_items = [];
      for (var i = 0; i < this.shop_items.length; i++) {
        this.invoice_shop_items.push(this.make_shop_item(i));
      }
      this.recalc_item_totals();
      this.recalc_totals();
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    cancel() {
      this.visible = false;
      this.$emit("cancel");
    },

    confirm() {
      const company_ids = this.companies.map(item => item.company_id);
      const shop_items = this.invoice_shop_items.map(item => ({ shop_item_id: item.shop_item_id, quantity: item.quantity, amount: item.amount }));
      const options = {due_date: this.due_date, text: this.box_text };
      this.visible = false;
      this.$emit("confirm", {company_ids: company_ids, shop_items: shop_items, options: options });
    },

    make_shop_item(index) {
      if (this.shop_items.length && index < this.shop_items.length) {
        let shop_item = this.shop_items[index];
        return  {
          shop_item: index,
          shop_item_id: shop_item.shop_item_id,
          amount:  shop_item.amount,
          currency: shop_item.currency,
          quantity_type: shop_item.quantity_type,
          quantity: shop_item.quantity_type === 'QUANTITY' ? 1 : this.company_member_count,
          vat: shop_item.amount_vat,
          tot_vat: 0,
          tot_amount: 0
        };
      }
      return null;
    },

    add_shop_item() {
      const item = this.make_shop_item(0);
      if (item) {
        this.recalc_item_totals(item);
        this.invoice_shop_items.push(item);
        this.recalc_totals();
      }
    },

    delete_shop_item(row) {
      this.invoice_shop_items.splice(row.index, 1);
      this.recalc_totals();
    },

    recalc_totals() {
      this.totals.vat = this.totals.exc_vat = 0;
      this.invoice_shop_items.forEach((item) => {
        this.totals.vat += item.tot_vat;
        this.totals.exc_vat += item.tot_amount;
      });
      this.totals.inc_vat = this.totals.vat + this.totals.exc_vat;
    },

    recalc_item_totals(item) {
      if (item) {
        item.tot_vat = item.vat * item.quantity;
        item.tot_amount = item.amount * item.quantity;
      }
      else { // recalc all items
        this.invoice_shop_items.forEach((item) => {
          this.recalc_item_totals(item);
        });
      }
    },

    selected_company_changed() {
      this.company_member_count = this.companies[this.selected_company].num_members;
      this.invoice_shop_items.forEach((item) => {
        if (item.quantity_type === 'MEMBERS') {
          item.quantity = this.company_member_count;
        }
      });
      this.recalc_item_totals();
      this.recalc_totals();
    },

    shop_item_changed(row) {
      const item = this.make_shop_item(row.item.shop_item);
      this.recalc_item_totals(item);
      this.invoice_shop_items[row.index] = item;
      // Vue3 compatability for Vue.set
      this.invoice_shop_items = [...this.invoice_shop_items];

      this.recalc_totals();
    },

    amount_changed(row) {
      this.recalc_item_totals(row.item);
      this.recalc_totals();
    },

    quantity_changed(row) {
      this.recalc_item_totals(row.item);
      this.recalc_totals();
    },
  },

  data() {
    return {
      visible: false,
      selected_company: null,
      company_member_count: 0,
      totals: {
        vat: 0, exc_vat: 0, inc_vat: 0
      },
      due_date: '',
      box_text: '',
      invoice_shop_items: [],
      headers: [
        {
          key: 'shop_item',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_ARTICLE'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center'
        },
        {
          key: 'amount',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_AMOUNT'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center'
        },
        {
          key: 'currency',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_CURRENCY'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center'
        },
        {
          key: 'quantity_type',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_QUANTITY_TYPE'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center',
          formatter: (_, __, item) => {
            if (item.quantity_type === 'QUANTITY') { return this.$t('MODAL.INVOICE_CREATE.QUANTITY_TYPE_1'); }
            if (item.quantity_type === 'MEMBERS') { return this.$t('MODAL.INVOICE_CREATE.QUANTITY_TYPE_2'); }
            return null;
          },
        },
        {
          key: 'quantity',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_QUANTITY'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center',
        },
        {
          key: 'vat',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_VAT'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center'
        },
        {
          key: 'tot_vat',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_TOT_VAT'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center'
        },
        {
          key: 'tot_amount',
          show: true,
          sortable: false,
          label: this.$t('MODAL.INVOICE_CREATE.TH_TOT_AMOUNT'),
          thClass: 'py-2 pl-4',
          tdClass: 'py-1 pl-4 vertical-center'
        },
        {
          key: 'actions',
          show: true,
          sortable: false,
          label: '',
          thClass: 'py-2 pl-4 w-100',
          tdClass: 'py-1 pl-4 vertical-center'
        }
      ]
    };
  },

  mounted() {
  },

};

</script>
